* {
  margin: 0;
  padding: 0;
  font-family: Nunito;
}


.data {

  border: 1px solid black;
  padding: 10px;
  margin: 10px;
  box-shadow: 2px 3px 4px grey;
}

.stats {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  /* width: 50%; */
  
}

.stat-heading {
  color: red;
  margin: 0 0 20px 0;
}

.login {
  display: flex;
  flex-direction: column;
  width: 25%;
  margin: 0 auto;
  border: 1px solid black;
  padding: 25px;
}

.login-fields {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.login-fields input {
  padding: 10px;
}

.add-data {
  width: 25%;
  margin: 0 auto;
}

.add-data-fields {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.add-data input {
  padding: 10px;
  margin: 0 0 5px 0;
}

button {
  padding: 10px;
}

.headings {
  display: flex;
  justify-content: space-around;
}

.heading {
  font-weight: bold;
  color:#1F75FE;
}

.title {
  text-align: center;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .add-data {
    width: 100%;
  }

  .stats {
    display: flex;
    flex-direction: column;
  }

  .stat-heading {
    margin: 10px 0 0 0;
  }
}